import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { Suspense, useContext } from 'react';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import { Loader } from 'semantic-ui-react';
import NavigationView from './components/NavigationView';
import { AuthContext, Role } from './context/auth';
import LoginView from './views/LoginView';
import ErrorBoundary from './views/ErrorBoundary';
import './AppRouter.css';
function PrivateRoute({ element, roles, user }) {
    if (element == null) {
        return _jsx("div", { children: "not found" });
    }
    let allowed = false;
    if (roles != null && roles?.includes(Role.ALL)) {
        if (user?.role != null && Object.values(Role).includes(user?.role)) {
            allowed = true;
        }
    }
    else {
        for (const r in roles) {
            if (user?.role === r) {
                allowed = true;
                break;
            }
        }
    }
    // TODO aus Grid einen div mit fluid machen (rest flexbox)
    if (allowed) {
        if (user?.id != null && user.id > 0) {
            return (_jsxs("div", { style: { display: 'flex', flex: '1 1', flexDirection: 'row' }, children: [_jsx("div", { style: { flex: '1 0 100px' }, children: _jsx(NavigationView, {}) }), _jsx("div", { style: { flex: '0 1 100%', paddingRight: 5 }, children: _jsx(ErrorBoundary, { children: element }) })] }));
        }
    }
    else {
        localStorage.setItem('redirect-uri', window.location.pathname);
    }
    return _jsx(LoginView, {});
}
function SuspenseLoader({ childeren }) {
    return (_jsx(Suspense, { fallback: _jsx(Loader, { active: true, inline: "centered" }), children: childeren }));
}
function AppRouter() {
    const { user } = useContext(AuthContext);
    const DashboardView = React.lazy(async () => await import('./views/DashboardView'));
    const ListView = React.lazy(async () => await import('./views/ListView'));
    const NewFixtureView = React.lazy(async () => await import('./views/NewFixtureView'));
    const SpotFixtureDetails = React.lazy(async () => await import('./views/SpotFixturesDetails'));
    const ClaimsView = React.lazy(async () => await import('./views/ClaimsView'));
    const FreightView = React.lazy(async () => await import('./views/FreightView'));
    const DemurrageCalculation = React.lazy(async () => await import('./views/DemurrageCalculation'));
    const TcFixtureDetails = React.lazy(async () => await import('./views/TcFixturesDetails'));
    const router = createBrowserRouter([
        {
            path: '/login',
            element: _jsx(LoginView, {})
        },
        {
            path: '/',
            element: (_jsx(PrivateRoute, { element: _jsx(SuspenseLoader, { childeren: _jsx(DashboardView, {}) }), roles: [Role.ALL], user: user }))
        },
        {
            path: '/search',
            element: (_jsx(PrivateRoute, { element: _jsx(SuspenseLoader, { childeren: _jsx(ListView, { searchOpen: true }) }), roles: [Role.ALL], user: user }))
        },
        {
            path: '/list',
            element: (_jsx(PrivateRoute, { element: _jsx(SuspenseLoader, { childeren: _jsx(ListView, {}) }), roles: [Role.ALL], user: user }))
        },
        {
            path: '/new-fixture',
            element: (_jsx(PrivateRoute, { element: _jsx(SuspenseLoader, { childeren: _jsx(NewFixtureView, {}) }), roles: [Role.ALL], user: user }))
        },
        {
            path: '/spot-fixture/:id',
            element: (_jsx(PrivateRoute, { element: _jsx(SuspenseLoader, { childeren: _jsx(SpotFixtureDetails, {}) }), roles: [Role.ALL], user: user }))
        },
        {
            path: '/spot-fixture/:id/claims',
            element: (_jsx(PrivateRoute, { element: _jsx(SuspenseLoader, { childeren: _jsx(ClaimsView, {}) }), roles: [Role.ALL], user: user }))
        },
        {
            path: '/spot-fixture/:id/freight',
            element: (_jsx(PrivateRoute, { element: _jsx(SuspenseLoader, { childeren: _jsx(FreightView, {}) }), roles: [Role.ALL], user: user }))
        },
        {
            path: '/spot-fixture/:id/demurrage-calculation',
            element: (_jsx(PrivateRoute, { element: _jsx(SuspenseLoader, { childeren: _jsx(DemurrageCalculation, {}) }), roles: [Role.ALL], user: user }))
        },
        {
            path: '/tc-fixture/:id',
            element: (_jsx(PrivateRoute, { element: _jsx(SuspenseLoader, { childeren: _jsx(TcFixtureDetails, {}) }), roles: [Role.ALL], user: user }))
        }
    ]);
    return _jsx(RouterProvider, { router: router });
}
export default AppRouter;
