import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Menu, Icon } from 'semantic-ui-react';
import { AuthContext } from '../context/auth';
import { theme } from 'src/theme';
function NavigationView() {
    const [activeItem, setActiveItem] = useState('');
    const navigate = useNavigate();
    const { logOut } = useContext(AuthContext);
    return (_jsxs(Menu, { className: "hidden-print", fixed: "left", icon: "labeled", style: {
            paddingTop: 5,
            backgroundColor: theme.colors.LIGHTGRAY
        }, vertical: true, children: [_jsx(Menu.Item, { active: activeItem === 'dashboard', content: _jsx("img", { style: { paddingLeft: 3 }, alt: "operations logo", id: "btn-home", src: theme.logo }), onClick: () => {
                    setActiveItem('dashboard');
                    navigate('/');
                }, styles: { outerWidth: '1em' } }, "ship"), _jsx(Menu.Item, { active: activeItem === 'list', content: _jsx(Icon, { name: "list", size: "big" }), onClick: () => {
                    setActiveItem('list');
                    navigate('/list');
                } }, "list"), _jsx(Menu.Item, { active: activeItem === 'new', content: _jsx(Icon, { name: "plus circle", size: "big" }), onClick: () => {
                    setActiveItem('new');
                    navigate('/new-fixture');
                } }, "new"), _jsx(Menu.Item, { content: _jsx(Icon, { name: "log out", size: "big" }), onClick: () => {
                    logOut();
                } }, "log-out"), _jsx("div", { style: {
                    bottom: 10,
                    color: 'GrayText',
                    position: 'absolute',
                    textAlign: 'center',
                    paddingLeft: 25
                }, children: process.env.APP_VERSION ?? 'dev-' + new Date().toISOString() })] }));
}
export default NavigationView;
