import LogoTraxseaX from './assets/traxsea_X.svg';
import LogoTraxsea from './assets/traxsea_logo.svg';
import LogoLopstarStar from './assets/lopstar_Icon.svg';
import LogoLopstar from './assets/lopstar_Logo.svg';
const albisTheme = {
    colors: {
        BLUE: '#2C3F50',
        LIGHTBLUE: '#ccebff',
        LIGHTGRAY: '#DFE8EF',
        LIGHTERGRAY: '#E5E8EB',
        YELLOW: 'rgba(252, 254, 22, 0.6)',
        RED: '#ED004F',
        LIGHTRED: 'hsl(0,80%,44%,0.3)',
        GREEN: '#A2C05C',
        BUTTONGREY: '#C0C6CC',
        DARKGREY: '#979FA5',
        NONE: 'none',
    },
    loginLogo: LogoLopstar,
    logo: LogoLopstarStar,
};
const traxseaTheme = {
    colors: {
        BLUE: '#2C3F50',
        LIGHTBLUE: '#ccebff',
        LIGHTGRAY: '#DFE8EF',
        LIGHTERGRAY: '#E5E8EB',
        YELLOW: 'rgba(252, 254, 22, 0.6)',
        RED: '#ED004F',
        LIGHTRED: 'hsl(0,80%,44%,0.3)',
        GREEN: '#A2C05C',
        BUTTONGREY: '#C0C6CC',
        DARKGREY: '#979FA5',
        NONE: 'none'
    },
    loginLogo: LogoTraxsea,
    logo: LogoTraxseaX,
};
export const themeConfig = {
    themes: {
        albis: albisTheme,
        traxsea: traxseaTheme,
    },
};
export var FontSize;
(function (FontSize) {
    FontSize[FontSize["TINY"] = 10] = "TINY";
    FontSize[FontSize["SMALL"] = 12] = "SMALL";
    FontSize[FontSize["MEDIUM"] = 14] = "MEDIUM";
    FontSize[FontSize["LARGE"] = 18] = "LARGE";
    FontSize[FontSize["HUGE"] = 20] = "HUGE";
})(FontSize || (FontSize = {}));
export const theme = (() => {
    switch (process?.env?.APP_THEME) {
        case 'albis':
            return themeConfig.themes.albis;
        default:
            return themeConfig.themes.traxsea;
    }
})();
