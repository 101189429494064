import { cacheTime } from './constants';
import React from 'react';
import { theme } from 'src/theme';
export const useAsyncError = () => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [_, setError] = React.useState();
    return React.useCallback((e) => {
        setError(() => {
            throw e;
        });
    }, [setError]);
};
export function currencyFormatToString(value, currency) {
    return (value ?? 0).toLocaleString(undefined, currency != null
        ? {
            style: 'currency',
            currency
        }
        : { minimumFractionDigits: 2 });
}
export function formatDateString(d) {
    try {
        if (d != null) {
            const dd = typeof d === 'string' ? new Date(d) : d;
            if (dd instanceof Date) {
                return dd.toLocaleDateString(undefined, {
                    day: '2-digit',
                    month: '2-digit',
                    year: '2-digit',
                    timeZone: 'UTC'
                });
            }
        }
    }
    catch (error) {
        console.error(error);
    }
    return '';
}
export function notEqualFloat(a, b, epsilon = 0.001) {
    if ((a === undefined && b !== undefined) ||
        (b === undefined && a !== undefined)) {
        return true;
    }
    if (a === b) {
        return false;
    }
    return Math.abs(Number(a) - Number(b)) > epsilon;
}
export function formatDateInput(d) {
    if (d != null) {
        const dd = typeof d === 'string' ? new Date(d) : d;
        if (checkDateIsValid(dd)) {
            return dd.toISOString().slice(0, 10);
        }
    }
    return '';
}
export function checkDateIsValid(date) {
    return date instanceof Date && !isNaN(date.valueOf());
}
export function formatDatetimeInput(d) {
    try {
        if (d != null) {
            const dd = typeof d === 'string' ? new Date(d) : d;
            if (checkDateIsValid(dd)) {
                return dd.toISOString().substring(0, 16);
            }
        }
    }
    catch (error) {
        console.error(error);
    }
    return '';
}
export function cleanUpInputString(input) {
    return input.replace(/\t/g, ' ');
}
export function cargoInfoDischargePortInfosToStr(ci) {
    const ports = ci?.data
        ?.map((lpi) => lpi?.attributes?.discharge_port_infos?.data?.map((p) => p.attributes?.port?.data?.attributes?.name))
        .filter((x) => x != null && x.length > 0)
        .filter((value, index, array) => array.indexOf(value) === index);
    return (ports ?? []).join('-');
}
export function getSafeDate(d) {
    const dd = getSafeDateRaw(d);
    if (dd != null) {
        dd.setHours(23, 59, 59, 59);
        return dd;
    }
}
export function toTitleCase(s) {
    return s
        .replace(/^[a-z]|^([A-Z]+)(?=[A-Z]|$)|([A-Z])+(?=[A-Z]|$)|([A-Z])(?=[a-z]+)/g, (m) => ' ' + m.toUpperCase())
        .replace('_', ' ')
        .trim();
}
export function MinutesToHoursDateMinutesString(mins) {
    if (mins === undefined) {
        return '';
    }
    const d = mins > 0 ? Math.floor(mins / 1440) : Math.round(mins / 1440); // 60*24
    const h = mins > 0
        ? Math.floor((mins - d * 1440) / 60)
        : Math.round((mins - d * 1440) / 60);
    const m = Math.round(mins % 60);
    return (d.toString().padStart(2, '0') +
        'd:' +
        h.toString().padStart(2, '0') +
        'h:' +
        m.toString().padStart(2, '0') +
        'm');
}
export function getSafeDateRaw(d) {
    if (d != null) {
        const dd = typeof d === 'string' ? new Date(d) : d;
        return dd instanceof Date ? dd : undefined;
    }
}
export function etaColor(eta) {
    const now = new Date();
    now.setHours(0, 0, 0, 0);
    const etaCheckedDate = getSafeDateRaw(eta?.attributes?.checked);
    const etaDate = getSafeDateRaw(eta?.attributes?.eta);
    etaCheckedDate?.setHours(0, 0, 0, 0);
    const t = etaCheckedDate == null
        ? undefined
        : (now.getTime() - (etaCheckedDate.getTime() ?? 0)) / 1000;
    if (etaCheckedDate != null &&
        etaDate != null &&
        now.getTime() > etaDate.getTime()) {
        return theme.colors.GREEN;
    }
    if (t != null && t < 60 * 60 * 24) {
        return theme.colors.GREEN;
    }
    if (t != null && t < 60 * 60 * 48) {
        return theme.colors.YELLOW;
    }
    if (t != null &&
        etaDate != null &&
        now.getTime() - etaDate.getTime() < 24 * 60 * 60 * 1000) {
        return theme.colors.RED;
    }
    return theme.colors.NONE;
}
export function getCurrentEta(fixture) {
    const now = new Date();
    now.setHours(0, 0, 0, 0);
    const cargoInfos = fixture?.attributes?.cargo_infos?.data ?? [];
    const dischargePortEtas = cargoInfos.flatMap((ci) => ci.attributes?.discharge_port_infos?.data
        ?.filter((pi) => pi.attributes?.eta != null)
        .map((pi) => {
        return {
            ...pi,
            type: 'dischargePort'
        };
    }));
    const loadPortEtas = cargoInfos.flatMap((ci) => (ci.attributes?.load_port_infos?.data ?? [])
        .filter((pi) => pi.attributes?.eta != null)
        .map((pi) => {
        return {
            ...pi,
            type: 'loadPort'
        };
    }));
    const portEtas = [...loadPortEtas, ...dischargePortEtas].filter((peta) => {
        const etaDate = getSafeDateRaw(peta?.attributes?.eta);
        etaDate?.setHours(0, 0, 0, 0);
        return (etaDate?.getTime() ?? 0) >= now.getTime();
    });
    portEtas.sort((a, b) => {
        const aEtaDate = getSafeDateRaw(a?.attributes?.eta);
        aEtaDate?.setHours(0, 0, 0, 0);
        const bEtaDate = getSafeDateRaw(b?.attributes?.eta);
        bEtaDate?.setHours(0, 0, 0, 0);
        if (bEtaDate == null) {
            return 1;
        }
        if (aEtaDate == null) {
            return -1;
        }
        return aEtaDate.getTime() > bEtaDate.getTime() ? -1 : 1;
    });
    return portEtas.length > 0 ? portEtas[portEtas.length - 1] : undefined;
}
export function isCacheExpired(timestamp) {
    if (timestamp == null) {
        return true;
    }
    return !!(timestamp != null && new Date().getTime() >= timestamp + cacheTime);
}
export function checkIfStartStop(pi, param) {
    let result = null;
    if (param === pi.attributes?.start_field) {
        result = '  Start';
    }
    else if (param === pi.attributes?.stop_field) {
        result = '  Stop';
    }
    return result;
}
